<template>
  <NuxtImg
    :src="isXmas ? '/cardano-spot-logo-light-xmas.png' : '/cardano-spot-logo-light.png'"
    alt="Cardano logo light"
    class="w-[172px] h-auto"
    format="webp"
    loading="lazy"
    quality="100"
    sizes="sm:172px md:172px lg:172px"
  />
</template>

<script lang="ts" setup>
import {useIsXmasMode} from '~/composables/common'

const isXmas = useIsXmasMode()
</script>
